import React, { useState, useEffect } from 'react';
import './About.scss';
import { motion } from 'framer-motion';
import { AppWrap, MotionWrap } from '../../wrapper';
import { media } from '@wix/api-client';
import { getAbout } from '../../api/portfolio-api';

const About = () => {
  const [abouts, setAbouts] = useState([]);

  async function fetchAbouts() {
    const abouts = await getAbout();
    setAbouts(abouts["_items"]);
  }

  useEffect(() => {
    fetchAbouts();
  }, [])

  return (
    <>
      <h2 className='head-text'>I Build <span>Full-Stack Applications</span> <br /> in the <span>Cloud</span></h2>

      <div className='app__profiles'>
        {abouts.map((about, index) => (
          <motion.div
            whileInView={{ opacity: 1}}
            whileHover={{ scale: 1.1}}
            transition={{ duration: 0.5, type: 'tween'}}
            className="app__profile-item"
            key={about.data.title + index}
          >
            <img src={media.getImageUrl(about.data.imgUrl).url} alt={about.data.title} />
            <h2 className="bold-text" style={{ marginTop: 20 }}>{about.data.title}</h2>
            <p className="p-text" style={{ marginTop: 10 }}>{about.data.description}</p>
          </motion.div>
        ))}
      </div>
    </>
  );
}

export default AppWrap(
  MotionWrap(About, 'app__about'),
  'about',
  "app__whitebg"
);