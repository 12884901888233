import { apiEndpoint } from "../config";
import Axios from "axios";

export async function getAbout() {
  const response = await Axios.get(`${apiEndpoint}/about`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
}

export async function getWorks() {
  const response = await Axios.get(`${apiEndpoint}/works`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
}

export async function getSkills() {
  const response = await Axios.get(`${apiEndpoint}/skills`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
}

export async function getExperiences() {
  const response = await Axios.get(`${apiEndpoint}/experiences`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
}

export async function sendContactInfo(contactInfo) {
  const response = await Axios.put(
    `${apiEndpoint}/sendContactInfo`,
    JSON.stringify(contactInfo),
    {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }
  );
  
  return response.data;
}
