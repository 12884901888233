import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { AppWrap, MotionWrap } from '../../wrapper';
import { media } from '@wix/api-client';
import './Skills.scss';
import { getExperiences, getSkills } from '../../api/portfolio-api';

const Skills = () => {
  
  const [experiences, setExperiences] = useState([]);
  const [skills, setSkills] = useState([]);

  async function fetchSkills() {
    const skills = await getSkills();
    setSkills(skills["_items"]);
  }

  async function fetchExperiences() {
    const exp = await getExperiences();
    setExperiences(exp["_items"]);
  }

  useEffect(() => {
    fetchSkills();
    fetchExperiences();
  }, [])

  return (
    <>
      <h2 className='head-text'>Skills & Experience</h2>

      <div className='app__skills-container'>
        <motion.div className='app__skills-list'>
          {skills.map((skill) => (
            <motion.div
              whileInView={{opacity: [0, 1]}}
              transition={{duration: 0.5}}
              className='app__skills-item app__flex'
              key={skill.data.title}
            >
              <div className='app__flex' style={{ backgroundColor: skill.data.bgColor }}>
                <img src={media.getImageUrl(skill.data.icon).url} alt={skill.data.title}/>
              </div>
              <p className='p-text'>{skill.data.title}</p>
            </motion.div>
          ))}
        </motion.div>

        <motion.div className='app__skills-exp'>
            {experiences.map((experience) => (
              <motion.div
                className='app__skills-exp-item'
                key={experience.data.title}
              >
                <div className='app__skills-exp-year'>
                  <p className='bold-text'>{experience.data.title}</p>
                </div>
                <motion.div className='app__skills-exp-works'>
                  {experience.data.works.map((work) => (
                    <>
                      <motion.div
                        whileInView={{opacity: [0, 1]}}
                        transition={{duration: 0.5}}
                        className='app__skills-exp-work'
                        data-tip
                        data-for={work.name}
                        key={work.name}
                        id={work.name}
                      >
                        <h4 className='bold-text'>{work.name}</h4>
                        <p className='p-text'>{work.company}</p>
                      </motion.div>
                      <ReactTooltip
                        id={work.name}
                        effect="solid"
                        arrowColor="#fff"
                        className='skills-tooltip'
                        /* anchorSelect={`#${work.name}`} */
                        /* anchorSelect='.app__skills-exp-work' */
                        float='true'
                      >
                        {work.desc}
                      </ReactTooltip>
                    </>
                  ))}
                </motion.div>
              </motion.div>
            ))}
        </motion.div>
      </div>
    </>
  );
}

export default AppWrap(
  MotionWrap(Skills, 'app__skills'),
  'skills',
  "app__primarybg"
);