import React from 'react';
import { BsInstagram } from 'react-icons/bs';
import { FaGithub } from 'react-icons/fa';

const SocialMedia = () => {
  return (
    <div className='app__social'>
        <div>
            <FaGithub href='https://www.github.com/jordan-haynes'/>
        </div>
        <div>
            <BsInstagram />
        </div>
    </div>
  )
}

export default SocialMedia;